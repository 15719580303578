import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { first, map, shareReplay, skipWhile, switchMap, tap } from "rxjs/operators";
import { GroupPermission } from "../../admin/model/permission";
import { PermissionsTableService } from "../../admin/services/permissions-table.service";
import { AuthService, UserSession } from "../services/auth.service";

export type GroupsWithPermission = {
    permission: string;
}

@Injectable()
export class RestrictIfService  {

    _cachedPermissions = new Set<string>();

    _cachedRoles = new Set<string>();

    _loadPermissions: Observable<boolean>

    constructor(private permissionsService: PermissionsTableService,
        private authService: AuthService) {

        this._loadPermissions = this.authService.onUserSession
        .pipe(
            skipWhile(userInfo => !userInfo?.idToken?.payload["custom:group"]),
            first(),
            map((userInfo: UserSession) => userInfo.idToken.payload["custom:group"]?.split("|") || []),
            tap((groups: string[]) => groups.forEach(role => this._cachedRoles.add(role))),
            switchMap((groups: string[]) =>
                this._cachedPermissions.size
                ? of(true)
                : this.permissionsService.getGroupsWithPermissions()
                    .pipe(
                        first(),
                        tap((permissions: GroupPermission[]) => {
                            const usersPermissionsByGroup = permissions.filter(permission => groups.includes(permission.groupKey))
                            usersPermissionsByGroup.forEach(permission => {
                                this._cachedPermissions.add(permission.permissionKey);
                            });
                        }),
                        map(() => true)
                    )
            ),
            shareReplay()
        );

    }

    restrictTo(...permissions: string[]): Observable<boolean> {
        // TODO: This is temporary. We need to test the Connectivity Service without displaying it in production.
        if (permissions.includes('SANDBOX_ENV')) {
            const isSandbox = !!location.hostname.match(/test.sandbox|localhost/)?.length;
            return of(isSandbox);
        }
        
        return this._loadPermissions.pipe(
            switchMap(() => {
                for (const permission of permissions) {
                    if (this._cachedPermissions.has(permission)) {
                        return of(true);
                    }
                }

                return of(false);
            })
        );
    }

    hasRoles(...roles: string[]): Observable<boolean> {
      return this._loadPermissions.pipe(
        switchMap(() => {
          for (const role of roles) {
            if (this._cachedRoles.has(role)) {
              return of(true);
            }
          }

          return of(false);
        })
      );
    }

    permissionsLoaded(): Observable<boolean> {
        return this._loadPermissions;
    }
}

