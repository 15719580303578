import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NotRestrictedPipe } from "./not-restricted.pipe";
import { RestrictToDirective } from "./restrict-to.directive";
import { RestrictedPipe } from "./restricted.pipe";
import {RestrictToRolesDirective} from "./restrict-to-roles.directive";

@NgModule({
  declarations: [
    RestrictToDirective,
    NotRestrictedPipe,
    RestrictedPipe,
    RestrictToRolesDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    RestrictToDirective,
    NotRestrictedPipe,
    RestrictedPipe,
    RestrictToRolesDirective
  ]
})
export class RestrictionsModule {

}
