import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { RestrictIfService } from './restrict-if.service';

@Directive({
  selector: '[restrictToRoles]',
})
export class RestrictToRolesDirective {

  constructor(private service: RestrictIfService,
    private vcr: ViewContainerRef,
    private tempRef: TemplateRef<any>) { }

  @Input()
  set restrictToRoles(permission: string | string[]) {
    this.vcr.clear();

    if (typeof permission === 'string') {
      permission = [permission];
    }

    this.service.hasRoles(...permission)
      .pipe(first())
      .subscribe((hasPermission: boolean) => {
        if (hasPermission) {
          this.vcr.createEmbeddedView(this.tempRef);
        }
      });
    }

  ngOnDestroy(): void {
    this.vcr.clear();
  }

}
