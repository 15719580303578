import { Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { first, map } from "rxjs/operators";
import { PermissionUtils } from "src/app/utils/permissions.utils";
import { environment } from "src/environments/environment";
import { ApiService } from "../../shared/services/api.service";
import { GroupPermission, Permission, PermissionTableFunctionGroup } from "../model/permission";

const API_BASE_URL = environment.api.o3;

export enum PermissionsTableServiceRoutes  {
    GROUPS = 'groups',
    PERMISSIONS = 'permissions',
    GROUP_PERMISSION = 'group-permission',
    GROUP_PERMISSION_DELETE = 'group-permission/delete'
}

@Injectable({ providedIn: 'root' })
export class PermissionsTableService {
    constructor(private apiService: ApiService) { };

    getGroupsWithKeys(): Observable<PermissionTableFunctionGroup[]> {
        return this.apiService.get<PermissionTableFunctionGroup[]>(PermissionsTableServiceRoutes.GROUPS, API_BASE_URL)
        .pipe(
            first(),
            map(groups => groups.filter(group => PermissionUtils.isAllowedGroup(group.key)))
        );
    }

    getGroupsAndFunctions(): Observable<[Permission[], GroupPermission[]]> {
        const permissions$ = this.apiService.get<Permission[]>('permissions', API_BASE_URL);

        return forkJoin([permissions$, this.getGroupsWithPermissions()]).pipe(first());
    }

    getGroupsWithPermissions(): Observable<GroupPermission[]> {
        return this.apiService.get<GroupPermission[]>(PermissionsTableServiceRoutes.GROUP_PERMISSION, API_BASE_URL)
        .pipe(
            map(groups => groups.filter(group => PermissionUtils.isAllowedGroup(group.groupKey)))
        );
    }

    buildPost(permission: GroupPermission): Observable<any> {
        return this.apiService.post(PermissionsTableServiceRoutes.GROUP_PERMISSION, permission, API_BASE_URL);
    }

    buildDelete(permission: GroupPermission): Observable<any> {
        return this.apiService.delete(PermissionsTableServiceRoutes.GROUP_PERMISSION_DELETE, permission, API_BASE_URL);
    }

}